.skills-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
}

.svg-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.my-skills {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
}

#skills-intro {
    margin-top: 5rem;
    justify-content: flex-end;
}

.icons-container {
    /* display: flex;
    flex-direction: column;
    width: 7rem;
    height: 7rem;
    margin: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 10rem;
    /* height: 30rem; */
    margin: 10px;
}

.icons-container p {
    color: #3dff94;
}

@media only screen and (max-width: 850px) {

    .icons-container {
        width: 25%;
    }

    .skills-holder {
        width: 80%;
    }
}
