html {
  scroll-behavior: smooth;
}

* {
  color: #0d0d0d;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: #21242e;

  /* background-image: linear-gradient(50deg, #151C2A 0%, #275f6b 50%, #5bd6c2 100%); */

  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #21242e;
}

::-webkit-scrollbar-thumb {
  background-color: #edf2f4;
}
