.top-holder {
  position: relative;
  overflow-x: hidden;
  /* prettier-ignore */
  .blob {
    position: absolute;
    width: 800px;
    height:800px;
    box-shadow:
    inset 10px 10px 200px 10px #ee6bff,
    inset 10px 10px 200px 10px #ff6bce,
    inset 10px 10px 400px 700px #ffaa4b
    ;


    mix-blend-mode: color-dodge;
    -webkit-animation: move 25s infinite alternate;
    animation: move 25s infinite alternate;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
    /* -webkit-filter: blur(15px); */
    filter: blur(30px);
  }
}
@keyframes move {
  from {
    /* transform: translate(min(20%, 100vw - 150px), 0px) rotate(180deg); */
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(min(50%, 100vw - 300px), 100px) rotate(180deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}
.test-svg {
  transform: scale(1);
}

.landing-holder {
  padding: 6rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
  justify-content: center;
  display: flex;
  height: 500px;
  /* background-image: url('../assets/bg.svg'); */
  /* background-image: linear-gradient(to right bottom, #1e202e, #232536, #272a3f, #2c3047, #313550, #313550, #313550, #313550, #2c3047, #272a3f, #232536, #1e202e); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */
  align-items: center;
  flex-direction: column;
  color: #fff;
  /* background: linear-gradient(to right, rgba(245, 245, 245, 0.383rgba(245, 245, 245, 0.284).8)), url('../assets/bg.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 6rem 8rem 6rem 12.5rem; */
}
.left-landing-container {
  overflow-x: hidden;
  display: flex;
  justify-self: flex-start;
  height: 100%;
  width: 60%;
  /* background-color: red; */
  background: rgba(134, 132, 132, 0.15);
  backdrop-filter: blur(2px);
  /* -webkit-backdrop-filter: blur( 18px ); */
  border-right: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1.5rem;
  z-index: 10;
  color: whitesmoke;
}

.node-container {
  display: flex;
  justify-self: flex-start;
  height: 100%;
  width: 60%;
  background: rgba(134, 132, 132, 0.15);
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border-right: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1.5rem;
  z-index: 1;
  color: whitesmoke;
}

/* .blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  box-shadow:
    inset 0 0 0 5px rgba(255,255,255, 0.6),
    inset 100px 100px 0 0px #fa709a,
    inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
} */

.align-intro {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.landing {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  /* flex-direction: column; */
  color: #e6e9e9;
  width: 100%;
}

.animation-holder {
  margin-top: 80px;
}

.greeting-animation,
.typed-cursor {
  margin-top: 30px;
  font-size: 40px;
}

.greeting-animation {
  /* color: #3dff94; */
  color: #6e9075;
  /* color: #6E9075; */
}

.my-name,
.my-role {
  color: #edf2f4;

  /* -webkit-background-clip: text; */
  /* background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* background-image: linear-gradient(to right, #9076fc, #6de1e7); */
  /* color: #3dff94; */
  font-weight: 200;
}

.my-intro {
  font-size: 4rem;
  width: 100%;
  color: #edf2f4;
}

.intro-talk {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 1.5rem;
  color: #edf2f4;
  line-height: 2rem;
}

@media (max-width: 700px) {
  .my-intro {
    font-size: 3rem;
  }

  .landing-holder {
    padding: 3rem 3rem 6rem 3rem;
  }

  .static-holder {
    display: none;
  }
}

/* RESPONSIVE MARGINS FOR TITLES */
@media (max-width: 900px) {
  .my-intro {
    font-size: 3rem;
  }
  .intro-talk {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .landing-holder {
    padding: 3rem 2rem 6rem 2rem;
  }

  .static-holder {
    display: none;
  }
  .about-holder .about-me-intro {
    margin: 6rem 2rem 2rem 2rem !important;
  }
  .about-section-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #projects-intro,
  #contact-heading {
    margin: 6rem 2rem 2rem 2rem;
  }

  .contact-container {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
