/*? **NAVBAR** ?*/
.navbar {
  position: fixed;
  display: flex;
  height: 50px;
  z-index: 1000;
  padding: 1rem 6rem;
  justify-content: space-between;
  color: #edf2f4;
  top: 0;
  left: 0;
  right: 0;
}
.has_scrolled {
  background-color: #21242eb5;
  backdrop-filter: blur(15px);

  box-shadow: 0 16px 50px -20px rgba(50, 50, 93, 0.25), 0 10px 16px -18px rgba(0, 0, 0, 0.3);
}

.navbar-logo {
  width: fit-content;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 200;
  color: #edf2f4;

  /*
    background-image: url('../images/dmLogo.svg');
    background-repeat: no-repeat;
    /* background-size: contain; */
  /* background-size: 100%; */
  /* width: 100%; */
  /* background-position: center; */
}

.logo-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #edf2f4;
}

.navbar a {
  height: 100%;
  text-decoration: none;
}

/*? **LINK HOLDER** ?*/
.link-holder {
  display: flex;
  flex-direction: row;
  color: #3dff94;
  align-items: center;
  font-size: 18px;
  /* 5dfff0*/
}

/*? **PAGE LINKS** ?*/
.page-links {
  column-gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  /* padding-right: 2%; */

  /* width: 80%; */
}

.page-links a {
  font-size: 1rem;
  display: flex;
  color: #edf2f4;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40px;
  border-bottom: 0;
  transition: ease-in-out 0.2s;
}

.page-links a:hover {
  transition: ease-in-out 0.2s;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ee6bff, #ffaa4b);
}

.page-links a::after {
  /* content: "";
  width: 0;
  height: 3px; */

  /* LINK HIGHLIGHT */
  /* background-image: linear-gradient(to right, #ee6bff, #ffaa4b);
  transition: width ease-in-out 0.2s;
  border-radius: 18px; */
}

.page-links a:hover::after {
  width: 100%;
}

/*? **RESUME** ?*/
.resume {
  /* z-index: 33; */
  /* text-align: center; */
  /* border: rgba(190, 190, 190, 0.493) 1px solid; */
  /* width: 90px; */
  border-radius: 5%;
}

.resume a {
}

/* .resume a:hover::after {
    width: 0;
} */

/*? **RESUME LINK** ?*/
.resume-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#resume-link {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

/*
.page-links a:last-child {
    width: 90px;
    border: rgba(190, 190, 190, 0.493) 1px solid;
    border-radius: 5%;
    margin-left: 40px;
}

.page-links a:last-child:before {
    content: none
} */
.hamburger {
  display: none;
}

.open_ham {
  display: none;
}
.ham-nav {
  display: none;
}

@media (max-width: 900px) {
  .navbar {
    padding: 1rem 2rem;
    /* justify-content: center; */
  }
}

@media (max-width: 700px) {
  .page-links span {
    display: none;
  }

  .hamburger {
    display: flex;
    height: 20px;
    width: 20px;
    background-image: url("../assets/hamburger.png");
    background-repeat: no-repeat;
    background-size: cover;
    transition: ease-in-out 0.3s;
  }

  .is_open {
    background-image: url("../assets/close.png");
  }

  .open_ham {
    display: flex;
    flex-direction: column;
    background-color: #21242e;
    position: absolute;
    right: 0;
    top: 70px;
    width: 100px;
    border: 1px #ffaa4b solid;
    padding: 1rem;
    align-items: center;
  }
}
