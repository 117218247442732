.my-projects {
  z-index: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* prettier-ignore */
  .blob2 {
    z-index: 2;
    position: absolute;
    width: 600px;
    height:600px;
    box-shadow:
    inset 10px 10px 00px 100px #6d8ee7,
    inset 100px 20px 230px 0px #58a2e8,
    inset 10px 10px 00px 60px #c64bff,
    inset 100px 150px 100px 900px #e36de7
    ;


    mix-blend-mode: color-dodge;
    -webkit-animation: move 25s infinite alternate;
    animation: move 25s infinite alternate;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
    /* -webkit-filter: blur(15px); */
    filter: blur(30px);
  }

  .project-blob-container {
    margin: 0 6rem;
    overflow: hidden;
  }
}
@keyframes move {
  from {
    transform: translate(min(10%, 100vw - 150px), 0px) rotate(180deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(min(90%, 100vw - 300px), 100px) rotate(-90deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

.project-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1400px;

  width: 100%;
  gap: 32px;
  margin-bottom: 6rem;
  z-index: 15;
  flex: 2 1;
}

.project {
  display: flex;
  color: #f7f7f7;
  z-index: 15;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  height: 16rem;
  border: 1px solid #f7f7f7;
  border-radius: 8px;
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(22px);
  background-color: #edf2f40f;
  max-width: 600px;
  width: 100%;
  .card-right {
    display: flex !important;
    gap: 16px;
    height: 100%;
    /* align-items: center; */
    .right-btn {
      display: flex !important;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
}

.project-desc {
  display: flex;
  margin: 0;
  height: fit-content;
  color: #edf2f4;

  /* margin-bottom: 70px; */
}

.project-1 {
  width: 100%;
  /* max-width: 362.37px; */
  border-radius: 8px;
}

.nomadika-pic {
  background-image: url("../images/Nomadika.PNG");
}

.travlr-pic {
  background-image: url("../images/Travlr.PNG");
}

.grammy-pic {
  background-image: url("../images/GrammyGram.PNG");
}

.oilflow-pic {
  background-image: url("../images/StackOilFLow.PNG");
}

.nomadika-pic,
.grammy-pic,
.oilflow-pic,
.travlr-pic {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  height: 35vh;
  width: 100%;
  border-radius: 8px;
}

.project-title {
  font-size: 25px;
  color: #edf2f4;

  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  margin: 0 0 32px 0;
}

.project p {
  /* color: #e6e9e9; */
}

.gh-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  border-radius: 8px;
}

.gh-link-holder {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.gh-link-holder a {
  text-decoration: none;
  color: #edf2f4;
  background-color: #151c29;
}

#projects-intro {
}

@media only screen and (max-width: 850px) {
  /* .project {
    width: 80%;
  } */
}

@media (max-width: 1495px) {
  .project-holder {
    justify-content: space-evenly;
  }
  .project {
    width: 40%;
    height: 18rem;
  }

  .project-desc {
    font-size: 14px;
  }
}
@media (max-width: 1282px) {
  .project {
    height: 26rem;
  }

  .card-right {
    flex-direction: column;
  }
}

@media (max-width: 801px) {
  .project {
    height: 30rem;
    width: 100%;
  }
  .my-projects .project-blob-container {
    margin: 0 1rem;
  }
}
